.modele {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        &--title {
            display: flex;
            justify-content: center;
            text-align: center;
        }
        &--sale {
            .grid-container {
                display: flex;
                justify-content: center;
            }
            .sale {
                .sale-img {
                    img {
                        width: 100%;
                        max-height: auto;
                        aspect-ratio: 16/9;
                        object-fit: cover;
                    }
                }
                &__info {
                    display: flex;
                    justify-content: space-between;
                    h5 {
                        align-self: center;
                    }
                }
            }
        }
        &--plus {
            display: flex;
            justify-content: center;
            margin: 75px 0 50px 0;
        }
    }
    @include phone {
    }
}
