.hero {
    display: flex;
    background-image: url(../../../assets/home/hero.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: calc(100vh - 99px);
    @include tablet {
        background-size: cover;
        background-position: center;
        height: calc(60vh - 99px);
    }
    .container {
        display: flex;
        flex-direction: column;
        margin: 100px 0 0 50px;
        @include tablet {
            margin: 0px;
        }
        .hero__title {
            color: white;
            @include phone {
                font-size: 30px;
            }
            span {
                font-size: 65px;
                @include phone {
                    font-size: 45px;
                }
            }
        }
        .hero__cta {
            margin-top: 50px;
            @include tablet {
                margin-top: 25px;
            }
        }
    }
}
