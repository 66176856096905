.boats__title {
    background-color: $color-gold;
    @include flex-center;
    height: 175px;
    h3 {
        color: #fff;
        padding: 20px;
        @include smallPhone {
            font-size: 35px;
        }
    }

    h3::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: 75px auto;
        width: 15%;
        height: 2px;
        background: $color-gold;
    }
}

.boats__products {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .boats__sortList {
        margin: 0 30px;
        @include phone {
            margin: 0;
        }
        .sortFilter-area {
            margin: 30px;
            @include smallPhone {
                margin: 50px 0px;
            }
        }
        .boats__sort {
            margin: 0px 0px 30px 0px;
            display: inline-flex;
            @include phone {
                margin: 0px 0px 0px 15px;
            }
            .boats__orderBy {
                padding: 0px 20px;
                background-color: rgba(128, 128, 128, 0.3);
                @include flex-center;
                @include smallPhone {
                    padding: 0px 10px 0px 20px;
                }
            }
            .boats__orderBy__Choices {
                padding: 10px 10px;
                background-color: rgba(128, 128, 128, 0.3);
                text-align: center;
                @include smallPhone {
                    .MuiSelect-select.MuiSelect-select {
                        padding-right: 12px;
                    }
                }
                &::before {
                    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
                }
                &::after {
                    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
                }
                .MuiSelect-select:focus {
                    background-color: rgba(0, 0, 0, 0);
                }
            }
        }
        .boats__products__list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(1fr);
            gap: 25px;
            @include tablet {
                grid-template-columns: 1fr;
            }
            .boats__products__card {
                display: flex;
                flex-direction: column;
                transition: transform 0.2s;

                img {
                    max-width: 100%;
                    height: auto;
                    aspect-ratio: 16/9;
                    object-fit: cover;
                }
                .boats__products__card__title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 25px;
                    color: black;
                    font-weight: 700;
                    font-size: 30px;
                    @include desktop {
                        font-size: 25px;
                    }
                    @include tablet {
                        font-size: 20px;
                    }
                    @include phone {
                        font-size: 18px;
                    }
                }
            }
            .boats__products__card:hover {
                cursor: pointer;
                transform: scale(1.03);
            }
        }
    }
    .boats__emptySearch {
        width: 1013px;
    }
    .boats__filterContainer {
        color: #fff;
        padding: 30px;
        background-color: #000;
        .MuiInputBase-root {
            color: #fff;
            background-color: $color-dark-gold;
            width: 200px;
            padding: 2px 5px;
            svg {
                path {
                    color: #fff;
                }
            }
        }
        .MuiInput-underline:before {
            border-bottom: 0px;
        }
        .MuiInput-underline:after {
            border-bottom: 0px;
        }
    }
}

@include phone {
    .boats__filter {
        margin: 30px 50px;
    }
    .sortFilter-area {
        display: flex;
        margin: 0px 30px;
        .filter-openButton {
            height: 50px;
            color: $color-gold;
            border: 1px solid $color-gold;
            transition: none;
            &:hover {
                border: 1px solid $color-gold;
                color: $color-gold;
            }
        }
    }
}

// dialog
#header-dialog {
    background-color: $color-gold;
}
