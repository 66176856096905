.nav {
    width: 100%;
    height: 99px;
    .nav-container {
        background-color: black;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        padding: 10px 90px;
        top: 0;
        z-index: 99;

        .nav__menu {
            align-self: center;
            a {
                padding: 0px 25px;
                color: white;
                font-size: 24px;
            }
            a:hover {
                color: $color-gold;
                transition: all 0.2s ease-out;
            }
        }

        @include tablet {
            // position: relative;
            padding: 10px 50px;

            .nav__menu {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;
                height: auto;
                padding: 15px 25px;
                position: absolute;
                top: 80px;
                right: 100%;
                opacity: 1;
                transition: all 0.5s ease;
                a {
                    margin: 10px 0px;
                }
                &:hover {
                    transition: ease 0.2s all;
                    color: $color-gold;
                }
            }
            .nav__menu.active {
                background-color: black;
                left: 0;
                opacity: 1;
                transition: all 0.5s, ease;
                z-index: 99;
            }

            .hbg-menu {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-100%, 100%);
                font-size: 2.1rem;
                cursor: pointer;
            }
        }

        @include phone {
            padding: 10px 50px;
        }

        &__logo {
            height: 100%;
        }
        .hbg-menu {
            display: flex;
            align-items: center;
            svg {
                fill: white;
            }
        }
    }

    .nav__cta {
        display: inline;
        align-self: center;
    }
}
