.boatsHero {
    display: flex;
    background: url(../../../assets/home/hero.jpg) no-repeat center center;
    background-size: cover;
    height: 500px;
    @include flex-center;
    h2 {
        color: white;
        font-weight: 300;
    }
    @include phone {
        height: 300px;
    }
}
