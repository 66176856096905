.container {
    padding: 50px 90px;
    @include tablet {
        padding: 50px;
        width: auto;
    }
    @include phone {
        padding: 35px;
        width: auto;
    }
}
