.news {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        &--header {
            display: flex;
            justify-content: center;
            text-align: center;
        }
        &--articles {
            display: flex;
            flex-direction: row;

            .grid-container {
                display: flex;
                justify-content: center;
            }
            .articles {
                margin: 10px;
                max-width: 803px;
                .news-img {
                    img {
                        max-width: 100%;
                    }
                }
                .news-info {
                    display: flex;
                    justify-content: space-between;
                    height: 55px;
                    @include phone {
                        flex-direction: column;
                    }
                    h5 {
                        align-self: center;
                    }
                }
                .underline-article::after {
                    @include phone {
                        visibility: hidden;
                    }
                }
            }
        }
        &--plus {
            margin: 50px;
        }
    }
}
