.newsletter {
    &__container {
        display: flex;
        .grid-container {
            justify-content: center;
            .grid-flex {
                display: flex;
                justify-content: center;
            }
        }
        &--subscribe {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .newsletter-email {
                width: 100%;
                form {
                    display: flex;
                    @include phone {
                        flex-direction: column;
                    }
                    .newsletter-input {
                        background-color: #f6f6f6;
                        border: 0;
                        width: 100%;
                        margin-right: 5px;
                        border-bottom: solid 3px #85754e;

                        @include phone {
                            height: 58px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
        &--image {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            img {
                max-width: 100%;
            }
        }
    }
}
