* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}
html {
    scroll-behavior: smooth;
    font-family: $font-quantico;
}

button,
h1,
h2,
h3,
h4,
h5,
h6,
span {
    text-transform: uppercase;
}
h2 {
    font-size: 55px;
    line-height: 62px;
    @include phone {
        font-size: 40px;
    }
}
h3 {
    font-size: 45px;
    line-height: 50px;
    margin: 20px;
    @include phone {
        font-size: 35px;
    }
}
h5 {
    font-size: 30px;
    line-height: 35px;
    @include phone {
        font-size: 25px;
    }
}
p {
    font-family: $font-montserrat;
    font-size: 20px;
    line-height: 1.25em;
    word-spacing: 2px;
    @include phone {
        font-size: 18px;
    }
}
form {
    ::placeholder {
        color: black;
        font-size: 18px;
    }
    input,
    textarea,
    input:focus,
    textarea:focus {
        font-size: 20px;
        padding: 5px 20px 5px 20px;
        outline: none;
        resize: none;
    }
}
button {
    font-size: 24px;
    @include phone {
        font-size: 18px;
    }
}

// ligne en dessous des titres de sections
.underline-title {
    position: relative;
    // bottom: 50%;
    margin-bottom: 50px;
}
.underline-title:after {
    background-color: $color-gold;
    content: "";
    display: block;
    width: 254px;
    height: 4px;
    position: absolute;
    transform: translate(-50%, 0);
    bottom: -10px;
    left: 50%;
}

// ligne en dessous des articles
.underline-article {
    position: relative;
    bottom: 0;
}
.underline-article:after {
    background-color: $color-gold;
    content: "";
    display: block;
    width: 25%;
    height: 4px;
    position: absolute;
    transform: translate(-50%, 0);
    bottom: 0;
    left: 13%;
}

// anchor placement
.anchor {
    display: block;
    position: relative;
    top: -95px;
    visibility: hidden;
}
