.footer {
    background-color: black;
    &__container {
        .bottom-border {
            border-bottom: solid 3px $color-gold;
        }
        .grid-center {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            @include phone {
                justify-content: flex-start;
            }
        }
        &--nav {
            display: flex;
            flex-direction: column;
            .footer-nav-title {
                font-size: 30px;
                color: white;
                margin-bottom: 20px;
            }
            .footer-nav-link {
                display: flex;
                flex-direction: column;
                a {
                    color: white;
                }
                a:hover {
                    color: $color-gold;
                    transition: ease 0.2s all;
                }
            }
        }
        &--logo {
            a {
                img {
                    max-width: 100%;
                }
            }
        }
        &--info {
            .footer-info-title {
                font-size: 30px;
                color: white;
                margin-bottom: 20px;
            }
            .footer-info-socials {
                margin-bottom: 20px;
                a {
                    margin-right: 25px;
                    font-size: 25px;
                    color: $color-gold;
                }
                a:hover {
                    color: white;
                    transition: ease 0.2s all;
                }
            }
            .footer-info-contact {
                display: flex;
                flex-direction: column;
                white-space: nowrap;
                a {
                    color: white;
                }
                a:hover {
                    color: $color-gold;
                    transition: ease 0.2s all;
                }
            }
        }
    }
}
