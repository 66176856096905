@mixin desktop {
    @media (max-width: 1280px) {
        @content;
    }
}
@mixin tablet {
    @media (max-width: 1024px) {
        @content;
    }
}
@mixin smallTablet {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin phone {
    @media (max-width: 600px) {
        @content;
    }
}

@mixin smallPhone {
    @media (max-width: 450px) {
        @content;
    }
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
