.cta {
    &__container {
        display: flex;
        flex-direction: row;
        &--left {
            background-image: url(../../../assets/home/cta.jpg);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            height: 100%;
        }
        &--right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            color: white;
            background-color: black;
            text-align: center;
            .cta-title {
                h3 {
                    padding-bottom: 25px;
                }
            }
            .cta-text {
                margin-bottom: 50px;
            }
            .cta-btn {
            }
        }
    }
}
