.lh {
    display: flex;
    justify-content: center;
    color: white;
    background-color: black;
    position: relative;
    span {
        position: relative;
        bottom: 25px;
        @include phone {
            bottom: 20px;
        }
        a {
            color: white;
        }
        a:hover {
            color: $color-gold;
            transition: ease 0.2s all;
        }
    }
}
