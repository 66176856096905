.boat__container {
    .boat__galleryContent__container {
        display: grid;
        grid-template-columns: 60% 40%;
        grid-template-rows: repeat(1fr);
        gap: 50px;
        @include tablet {
            grid-template-columns: 1fr;
        }
        .boat__gallery {
            @include smallPhone {
                margin-top: 30px;
            }
            img {
                aspect-ratio: 16/9;
                object-fit: cover;
                @include phone {
                    max-height: 35vh;
                }
            }
            .carousel-root {
                .carousel {
                    @include phone {
                        button {
                            display: none;
                        }
                    }
                    .control-arrow::before {
                        margin: unset;
                    }
                    .slider-wrapper {
                        max-height: 600px;
                        max-width: 1000px;
                    }
                    .thumbs-wrapper {
                        display: flex;
                        justify-content: center;
                        .control-arrow {
                            color: red;
                        }
                        .thumbs {
                            display: flex;
                            justify-content: center;
                            flex-shrink: unset !important;

                            li.thumb {
                                width: unset !important;
                                max-width: 80px;
                                transition: none;
                                border: none;
                                aspect-ratio: 16/9;
                                &:hover {
                                    border: unset !important;
                                }
                                &:focus {
                                    border: unset !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .boat__description {
            @include phone {
                margin-top: 0px;
            }
            h3 {
                color: black;
                position: relative;
                display: inline-block;
                margin-bottom: 20px;
            }
            h3::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0px;
                margin: 0px auto;
                height: 2px;
                background: $color-gold;
            }
        }
    }
}

.boatMedia {
    display: flex;
    justify-content: center;
    .boatMedia__card {
        flex-direction: column;
        margin: 10px 30px;
        @include flex-center;
        .boatMedia__card__cta {
            @include flex-center;
            width: 300px;
            display: flex;
            margin-top: 15px;
            background-color: $color-gold;
            color: white;
            &:hover {
                background-color: black;
            }
        }
        a {
            font-size: 18px;
            color: black;
            &:hover {
                color: $color-gold;
            }
        }
    }
    @include tablet {
        flex-direction: column;
        .boatMedia__card {
            margin: 30px;
        }
    }
}

ul.thumbs {
    transform: none !important;
}
