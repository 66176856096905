.contact {
    background-color: black;
    color: white;
    &__container {
        .grid-display {
            display: inline-grid;
            align-items: center;
        }
        display: flex;
        &--left {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .contact-title {
                h3 {
                    margin: 20px;
                }
            }
            .contact-text {
                margin-bottom: 50px;
            }
            .contact-cta {
            }
        }
        &--right {
            form {
                display: flex;
                flex-direction: column;
                input[type="text"],
                select {
                    font-family: $font-montserrat;
                    height: 2rem;
                }
                textarea,
                select {
                    font-family: $font-montserrat;
                    height: 225px;
                    resize: none;
                }
            }
            .form-btn {
                display: flex;
                justify-content: center;
                padding: 20px;
            }
        }
    }
}
