.btn-gold-bg-black {
    background-color: $color-gold;
    border: none;
    padding: 15px 30px;
    color: white;
    font-family: $font-quantico;
}

.btn-gold-bg-black:hover {
    background-color: white;
    color: $color-gold;
    transition: all 0.2s ease-out;
}
.btn-white-bg-black {
    background-color: rgba(255, 255, 255, 0.88);
    border: none;
    padding: 15px 30px;
    color: $color-gold;
    font-family: $font-quantico;
    border: solid 3px $color-gold;
}

.btn-white-bg-black:hover {
    background-color: $color-gold;
    color: white;
    transition: all 0.2s ease-out;
}

.btn-gold-bg-white {
    background-color: $color-gold;
    border: none;
    padding: 15px 30px;
    color: white;
    font-family: $font-quantico;
}

.btn-gold-bg-white:hover {
    background-color: black;
    color: $color-gold;
    transition: all 0.2s ease-out;
}

.btn-white {
    background-color: white;
    border: 2px solid $color-gold;
    padding: 15px 30px;
    color: $color-gold;
    font-family: $font-quantico;
}

.btn-white:hover {
    background-color: $color-gold;
    border: 2px solid $color-gold;
    color: white;
    transition: all 0.2s ease-out;
}

.btn-lireplus {
    white-space: nowrap;
}
