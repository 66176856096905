.hero__boat {
    background: url(../../../assets/home/hero.jpg) no-repeat center center;
    background-size: cover;
    height: 639px;
    width: 100%;
    @include flex-center;
    h2 {
        @include flex-center;
        color: #fff;
        font-weight: 300;
        text-align: center;
        position: relative;
        padding: 50px 50px;
    }
    h2::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 35px;
        margin: 10px auto;
        width: 50%;
        height: 2px;
        background: $color-gold;
    }
    @include desktop {
        height: 500px;
    }
    @include smallTablet {
        height: 400px;
        h2 {
            font-size: 40px;
        }
    }
    @include phone {
        height: 300px;
        h2 {
            font-size: 30px;
        }
    }
}
